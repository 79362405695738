import React from "react"
import { Link } from "gatsby"
import Title from "../Globals/Title"
import fjp from "../../images/fjp.jpeg"
import ep from "../../images/ep.jpg"


function Info(props) {
  return (
    <section className="py-5">
      <div className="container">
        <Title title="Our story"/>
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <p className="lead text-muted mb-5">
              Dodion grew from the idea that technical excellence alone is not sufficient to deliver successful
              and sustainable digital transformation. When we engage with clients we bring all of the necessary
              technical
              skills and experience to deliver the outcomes required. Additionally, we contribute openly to supporting
              positive
              organisational and cultural change which our clients often ultimately value most.
            </p>
            <p className="lead text-muted mb-5">
              Our teams comprise carefully selected experienced and talented individuals who share our values that
              include openness
              and honesty when dealing with each other and our clients. We have an unrelenting commitment to quality
              that reaches through discovery, refinement and delivery to supporting live systems. Our people embrace
              agile ways of working where effective communication underpins a cycle of inspection and adaption that
              accelerates the pace of delivery and maintains focus on delivering business value.
            </p>
            <p className="lead text-muted mb-5">
              Today, cloud services offer enormous potential to allow you to meet needs in ways that are secure,
              scalable and provide great value for money. Dodion is passionate about helping you unlock that potential.
            </p>
            {/*<Link to="/about/">*/}
            {/*<button className="btn text-uppercase btn-blue">about page</button>*/}
            {/*</Link>*/}
          </div>

          <div className="row text-center" style={{ width: "100%" }}>

            <div className="container">
              <div className="row">
                <div className="col p-5" >
                  <span style={{ float: "right" }}>
                    {/*<a href="https://www.linkedin.com/in/frankphillips/">*/}
                    <img src={fjp} className="img-thumbnail fjp-image mx-auto" alt="Photograph of Frank Phillips"/>
                  {/*</a>*/}
                  <h6 className="font-italic text-muted p-2">Frank Phillips, MD</h6>
                  </span>

                </div>
                <div className="col p-5">
                  <span style={{ float: "left" }}>
                  {/*<a href="https://www.linkedin.com/in/frankphillips/">*/}
                  <img src={ep} className="img-thumbnail ep-image mx-auto" alt="Photograph of Elizabeth Phillips"/>
                  {/*</a>*/}
                  <h6 className="font-italic text-muted p-2">Elizabeth Phillips, CTO</h6>
                  </span>
                </div>
              </div>

            </div>


          </div>


        </div>

      </div>
    </section>
  )
}

export default Info
