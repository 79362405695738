import React from "react"
import BackgroundImage from "gatsby-background-image"
import logo from "../../images/dodion-w-512.svg"

function BackgroundSection({
                             img,
                             styleClass,
                             title,
                             children,
                           }) {
  if (title === "") {
    return (
      <BackgroundImage
        className={styleClass}
        fluid={img}>
        <img src={logo} style={{maxWidth: "50vw"}}/>
        {children}
      </BackgroundImage>
    )
  } else {
    return (
      <BackgroundImage
        className={styleClass}
        fluid={img}>
        <h1 className="title text-white text-uppercase text-center display-4 font-weight-bold">{title}</h1>
        {children}
      </BackgroundImage>
    )
  }
}

BackgroundSection.defaultProps = {
  title: "default title",
  styleClass: "default-background",
}

export default BackgroundSection
